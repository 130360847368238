import { Card, Typography,Col,Row } from 'antd';
import useWindowSize from './customHooks/useWindowSize';
const CoachTeam = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <Card title={<Title level={size.width < 800 ? 3 : 2}>教練團隊</Title>} style={{backgroundColor:'#dbebfa'}}>
                <Row>
                    <Col lg={12} style={{padding:'5%'}}>
                        <img style={{width:'100%'}}
                            alt="Sirius Hockey"
                            src="/image/coachTeam.png"
                        />   
                    </Col>
                    <Col lg={12} style={{padding:'5%'}}>
                        <Title level={size.width < 800 ? 4 : 3}>余俊軒 (Tony Yu) </Title>
                        <ul>
                            <li><Title level={size.width < 800 ? 4 : 3}>現役香港曲棍球代表隊成員 (10年年資，超過50個Caps)</Title></li>
                            <li><Title level={size.width < 800 ? 4 : 3}>曾參加亞運會及東亞運動會等大型運動會賽事</Title></li>
                            <li><Title level={size.width < 800 ? 4 : 3}>國際曲棍球聯會註冊教練</Title></li>
                            <li><Title level={size.width < 800 ? 4 : 3}>香港曲棍球總會註冊教練</Title></li>
                        </ul>
                    </Col>
                </Row>
            </Card>
        </div>
        
    )
}

export default CoachTeam;