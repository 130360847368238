import {Typography } from "antd"
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const FAQ = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <DefineCard 
                title={<Title level={size.width < 700 ? 3: 2}>常見問題</Title>} 
                // titleUnderCoverPhoto={<Title level={size.width < 700 ? 4 : 3}>我的理想 | 我的使命 | 我的信念</Title>}
                // description={
                //     <>
                //         <Title level={size.width < 700 ? 5 : 4}>香港曲棍球訓練學校是一所專注小童及青少年的曲棍球訓練的機構。秉承"小朋友是我們的將來"的理念，為香港兒童及青少年提供一個富趣味性及具系統性的曲棍球運動體驗和訓練。</Title>
                //         <Title level={size.width < 700 ? 5 : 4}>有兩大元素: </Title>
                //         <ul>
                //             <li><Title level={size.width < 700 ? 5 : 4}>細節，影響一切 - Small Things, Big Impact </Title></li>
                //             <li> <Title level={size.width < 700 ? 5 : 4}>心態，決定境界 </Title></li>
                //         </ul>
                //         <Title level={size.width < 700 ? 4 : 3}>點解會成立呢間曲棍球學校？</Title>
                //         <Title level={size.width < 700 ? 5 : 4}>其中一個好重要既原因係因為我真係好中意曲棍球呢種運動，更加中意係我出生既香港推廣呢種我好中意既運動。 </Title>
                //     </>
                    
                
                // } 
                image={        
                    <img
                        alt="Sirius Hockey"
                        src="/image/FAQ.jpg"
                    />   
                }>
            </DefineCard>
        </div>
        
    )
}

export default FAQ;