const menuArray = [

    {
        'key' : 'home',
        'pageName' : '主頁',
        'link' : '/home'
    },
    {
        'key' : 'latestNews',
        'pageName' : '最新資訊',
        'link' : '/latest-information'
    },
    // {
    //     'key' : 'leaguStanding',
    //     'pageName' : '聯賽排名',
    //     'link' : '/league-standing'
    // },
    {
        'key' : 'membership',
        'pageName' : '會員專區',
        'link' : '/membership'
    },
    {
        'key' : 'clubIntroduction',
        'pageName' : '球會介紹',
        'link' : '/clubIntroduction'
    },
    {
        'key' : 'courseIntroduction',
        'pageName' : '課程簡介',
        'link' : '/course-introduction'
    },
    {
        'key' : 'coaches',
        'pageName' : '教練團隊',
        'link' : '/coach-team'
    },
    {
        'key' : 'trainingSchedule',
        'pageName' : '訓練日期',
        'link' : '/training-schedule'
    },
    {
        'key' : 'FAQ',
        'pageName' : '常見問題',
        'link' : '/faq'
    },
    {
        'key' : 'contactUs',
        'pageName' : '聯絡我們',
        'link' : '/contact-us'
    },
    // {
    //     'key' : 'partnership',
    //     'pageName' : '合作機會',
    //     'link' : '/cooperate-opportunities'
    // },


]


export default menuArray;