import {Typography,Col,Row,Carousel } from "antd"
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const LatestInformation = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <DefineCard 
                title={<Title level={size.width < 800 ? 3 : 2}>聯賽排名</Title>} 
                description={
                    <>
                        <Title level={size.width < 800 ? 5 : 2}>Men's Sirius A</Title>
                        <img style={{width:'100%',paddingBottom:20}}
                            alt="Sirius Hockey"
                            src="/image/LeagueTableDiv3.png"
                        />   
                        <Title level={size.width < 800 ? 5 : 2}>Men's Sirius B</Title>
                        <img style={{width:'100%',paddingBottom:20}}
                            alt="Sirius Hockey"
                            src="/image/LeagueTableDiv5.png"
                        />   
                        <Title level={size.width < 800 ? 5 : 2}>Women's Sirius</Title>
                        <img style={{width:'100%',paddingBottom:20}}
                            alt="Sirius Hockey"
                            src="/image/WomanLeagueTableDiv3.png"
                        />   
                    </>
                    } 
                >
            </DefineCard>
        </div>
        
    )
}

export default LatestInformation;