import 'antd/dist/antd.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import ContextProvider from './Utils/MyContext';
import Home from './Home'
import ClubIntroduction from './ClubIntroduction'
import ContactUs from './ContactUs'
import EmailConfirmation from './EmailConfirmation'
import CoachTeam from './CoachTeam'
import FAQ from './FAQ'
import LatestInformation from './LatestInformation'
import TrainingSchedule from './TrainingSchedule'
import CourseIntroduction from './CourseIntroduction'
import CooperateOpportunities from './CooperateOpportunities'
import LeaguStanding from './LeagueStadning'
import Membership from './Membership'

const App = () => {
      return (
        
        <BrowserRouter>
          <ContextProvider>
            <AppHeader></AppHeader>
              <Routes>
                <Route>
                  <Route exact path="/home" element={<Home />}/>
                  <Route exact path="/membership" element={<Membership />}/>
                  <Route exact path="/clubIntroduction" element={<ClubIntroduction/>}/>
                  <Route exact path="/faq" element={<FAQ/>}/>
                  <Route exact path="/latest-information" element={<LatestInformation/>}/>  
                  <Route exact path="/league-standing" element={<LeaguStanding/>}/>                  
                  <Route exact path="/training-schedule" element={<TrainingSchedule/>}/>
                  <Route exact path="/course-introduction" element={<CourseIntroduction/>}/>
                  <Route exact path="/contact-us" element={<ContactUs/>}/>
                  <Route exact path="/email-confirmation" element={<EmailConfirmation/>}/>
                  <Route exact path="/coach-team" element={<CoachTeam/>}/>
                  {/* <Route exact path="/cooperate-opportunities" element={<CooperateOpportunities/>}/> */}
                  <Route path="*" element={<Home/>}/>
                </Route>
                <Route path="*" element={<Home/>}/>
              </Routes>
            <AppFooter></AppFooter>
          </ContextProvider>
        </BrowserRouter>
  );
}

export default App;
