import {Typography } from "antd"
const { Title } = Typography;
const EmailConfirmation = () => {

    return (
        <div style={{margin:30}}>
            <Title level={2} style={{paddingLeft:50,paddingTop:50}}>已經收到你的電郵查詢! 由於查詢人數眾多，我們會盡快回覆! </Title>
        </div>
    )
}
export default EmailConfirmation