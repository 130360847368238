import { Typography,Col,Row,Carousel } from 'antd';
import useWindowSize from './customHooks/useWindowSize';
import { InstagramEmbed } from 'react-social-media-embed';
import DefineCard from './DefineCard'
const Home = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div>
            {size.width < 700 ?
                <div style={{margin:'5%',backgroundColor: '#dbebfa',textAlign: 'justify'}}>
                    <DefineCard 
                        title={<Title level={size.width < 700 ? 3 : 2}>主頁</Title>} 
                        description={

                                <>
                                    <Row style={{paddingBottom:20}}> 
                                        <Col span={size.width < 700 ? 24 : 16}>
                                            <InstagramEmbed url="https://www.instagram.com/p/Cmk8dPQBUnT/?utm_source=ig_web_copy_link"  /> 
                                        </Col>
                                        <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                            <Title level={size.width < 700 ? 5 : 4}>
                                                2022 Merry Christmas 🎅🏻🎉🏑
                                            </Title>
                                        </Col>  
                                    </Row>     
                                    <Row style={{paddingBottom:20}}> 
                                        <Col span={size.width < 700 ? 24 : 16}>
                                            <InstagramEmbed url="https://www.instagram.com/p/Cl-zRnGJPvU/?utm_source=ig_web_copy_link"  /> 
                                        </Col>
                                        <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                            <Title level={size.width < 700 ? 5 : 4}>
                                                Best of luck to the HK Junior Team who will play the Test Series in Singapore tonight to prepare Junior AHF Cup in Jan 2023.
                                            </Title>
                                            <Title level={size.width < 700 ? 5 : 4}>
                                                Our Head coach Tony Yu will be one of the Senior Player and also be assistant coach to lead the Junior Team against SGP team.
                                            </Title>
                                        </Col>  
                                    </Row>     
                                    <Row style={{paddingBottom:20}}> 
                                        <Col span={size.width < 700 ? 24 : 16}>
                                            <img style={{width:'100%'}}
                                                alt="Sirius Hockey"
                                                src="/image/sirius_training.jpg"
                                            />   
                                        </Col>
                                        <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                            <Title level={size.width < 700 ? 5 : 4}>💡Pitch Confirmed ❗</Title>
                                            <Title level={size.width < 700 ? 5 : 4}>Location: Happy Valley Hockey Pitch 11</Title>
                                            <Title level={size.width < 700 ? 5 : 4}>Date: 21,28/10; 4,11,18,25/11; 2,16/12 (Every Friday night)</Title>
                                            <Title level={size.width < 700 ? 5 : 4}>Time: 20:30-22:00</Title>
                                            <Title level={size.width < 700 ? 5 : 4}>Enquiry: siriushockeyclub2019@gmail.com</Title>
                                        </Col>  
                                    </Row>     
                                    
                                    {/* <Row style={{paddingBottom:20}}> 
                                        <Title level={3}>每週賽果</Title>
                                    </Row>          
                                    <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                                        <div>
                                            <Row>
                                                <Col span={24} >
                                                    <img style={{width:'100%'}}
                                                        alt="Sirius Hockey"
                                                        src="/image/week_5_1.jpg"
                                                    />   
                                                </Col>
                                                <Col span={24} style={{padding:'5%'}}>
                                                    <Title level={5}>香港曲棍球男子冬季聯賽第五週賽事</Title>
                                                    <Title level={5}>男A隊 對手: RHOBA A</Title>
                                                    <Title level={5}>男A隊上半場以短角球先入兩球領先上半場，下半場男A隊體力透支，加上受傷病影響，只得11人應戰，被對手全場緊迫下連入三球，結果以2:3反敗對手。</Title>
                                                    <Title level={5}>賽果: 輸2:3</Title> 
                                                    <Title level={5}>入球: Michael Chow x2</Title>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col span={24} >
                                                    <img style={{width:'100%'}}
                                                        alt="Sirius Hockey"
                                                        src="/image/clubIntroduction.png"
                                                    />   
                                                </Col>
                                                <Col span={24} style={{padding:'5%'}}>
                                                    <Title level={5}>香港曲棍球男子冬季聯賽第五週賽事</Title>
                                                    <Title level={5}>男B隊  對手: 144U A</Title>
                                                    <Title level={5}>男B隊同樣受到傷病影響，雖然全場只得12人應戰，但頭三節都互有攻勢及不少入肉攻勢，可惜體力問題，結果在第四節被對手連入兩球，以0:2不敵對手。</Title>
                                                    <Title level={5}>賽果: 0:2</Title> 
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel> */}
                                    <Row style={{paddingBottom:20}}> 
                                        <Title level={3}>每週賽果</Title>
                                    </Row>           
                                    <Row style={{paddingBottom:20}}>
                                        <Col span={24} >
                                            <InstagramEmbed url="https://www.instagram.com/p/CmWDLe5BxhH/?utm_source=ig_web_copy_link"  />
                                        </Col>
                                        <Col span={24} >
                                            <InstagramEmbed url="https://www.instagram.com/p/Clc0hUEBCFX/?utm_source=ig_web_copy_link"  />
                                        </Col>
                                    </Row> 
                                    <div> 
                                        <Row style={{backgroundColor: '#dbebfa',paddingBottom:20}}>
                                            <Col span={24} >
                                                <img style={{width:'100%'}}
                                                    alt="Sirius Hockey"
                                                    src="/image/carousel_1.jpg"
                                                />   
                                            </Col>
                                            <Col span={24} style={{padding:'5%'}}>
                                                <Title level={5}>Sirius Mini Hockey</Title>
                                                <Title level={5}>歡迎小朋友一齊參加</Title>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                                        <div>
                                            <Row> 
                                                <Col span={24}>
                                                    <img style={{width:'100%'}}
                                                        alt="Sirius Hockey"
                                                        src="/image/carousel_4.jpg"
                                                    />   
                                                </Col>
                                                <Col span={24} style={{padding:'5%'}}>
                                                    <Title level={5}>由香港男子曲棍球代表隊成員余俊軒設計一系列課程</Title>
                                                </Col>                                
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col span={24} >
                                                    <img style={{width:'100%'}}
                                                        alt="Sirius Hockey"
                                                        src="/image/carousel_5.png"
                                                    />   
                                                </Col>
                                                <Col span={24} style={{padding:'5%'}}>
                                                    <Title level={5}>曾代表香港參與2018年印尼雅加達亞運會</Title>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col span={24} >
                                                    <img style={{width:'100%'}}
                                                        alt="Sirius Hockey"
                                                        src="/image/carousel_6.png"
                                                    />   
                                                </Col>
                                                <Col span={24} style={{padding:'5%'}}>
                                                    <Title level={5}>2012年首次代表香港隊出賽，至今已獲得超過50頂國際賽喼帽</Title>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel>
                                    <div>
                                        <Row style={{backgroundColor: '#dbebfa',paddingBottom:20}}>
                                            <Col span={24} >
                                                <img style={{width:'100%'}}
                                                    alt="Sirius Hockey"
                                                    src="/image/carousel_7.png"
                                                />   
                                            </Col>
                                            <Col span={24} style={{padding:'5%', backgroundColor: '#dbebfa'}}>
                                                <Title level={5}>多次接受傳媒訪問，向公眾推廣曲棍球運動</Title>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                                }
                            >
                    </DefineCard>
                </div> 
                :
                
                <div style={{margin:'5%',backgroundColor: '#dbebfa',textAlign: 'justify'}}>
                    <DefineCard 
                        title={<Title level={size.width < 700 ? 3 : 2}>主頁</Title>} 
                        description={

                        <>  
                            <Row style={{paddingBottom:20}}> 
                                <Col span={size.width < 700 ? 24 : 16}>
                                    <InstagramEmbed url="https://www.instagram.com/p/Cmk8dPQBUnT/?utm_source=ig_web_copy_link"  />
                                </Col>
                                <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                    <Title level={size.width < 700 ? 5 : 4}>
                                        2022 Merry Christmas 🎅🏻🎉🏑
                                    </Title>
                                </Col>  
                            </Row>
                            <Row style={{paddingBottom:20}}> 
                                <Col span={size.width < 700 ? 24 : 16}>
                                    <InstagramEmbed url="https://www.instagram.com/p/Cl-zRnGJPvU/?utm_source=ig_web_copy_link"  />
                                </Col>
                                <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                    <Title level={size.width < 700 ? 5 : 4}>
                                        Best of luck to the HK Junior Team who will play the Test Series in Singapore tonight to prepare Junior AHF Cup in Jan 2023.
                                    </Title>
                                    <Title level={size.width < 700 ? 5 : 4}>
                                        Our Head coach Tony Yu will be one of the Senior Player and also be assistant coach to lead the Junior Team against SGP team.
                                    </Title>
                                </Col>  
                            </Row>
                            <Row style={{paddingBottom:20}}> 
                                <Col span={size.width < 700 ? 24 : 16}>
                                    <img style={{width:'100%'}}
                                        alt="Sirius Hockey"
                                        src="/image/sirius_training.jpg"
                                    />   
                                </Col>
                                <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                    <Title level={size.width < 700 ? 5 : 4}>💡Pitch Confirmed ❗</Title>
                                    <Title level={size.width < 700 ? 5 : 4}>Location: Happy Valley Hockey Pitch 11</Title>
                                    <Title level={size.width < 700 ? 5 : 4}>Date: 21,28/10; 4,11,18,25/11; 2,16/12 (Every Friday night)</Title>
                                    <Title level={size.width < 700 ? 5 : 4}>Time: 20:30-22:00</Title>
                                    <Title level={size.width < 700 ? 5 : 4}>Enquiry: siriushockeyclub2019@gmail.com</Title>
                                </Col>  
                            </Row>
                            {/* <Row style={{paddingBottom:20}}> 
                                <Title level={3}>每週賽果</Title>
                            </Row>
                            <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                                <div>
                                    <Row> 
                                        <Col span={8} style={{padding:'5%'}}>
                                            <Title level={4}>香港曲棍球男子冬季聯賽第五週賽事</Title>
                                            <Title level={4}>男A隊 對手: RHOBA A</Title>
                                            <Title level={4}>男A隊上半場以短角球先入兩球領先上半場，下半場男A隊體力透支，加上受傷病影響，只得11人應戰，被對手全場緊迫下連入三球，結果以2:3反敗對手。</Title>
                                            <Title level={4}>賽果: 輸2:3</Title> 
                                            <Title level={4}>入球: Michael Chow x2</Title>
                                        </Col>  
                                        <Col span={16}>
                                            <img style={{width:'100%'}}
                                                alt="Sirius Hockey"
                                                src="/image/week_5_1.jpg"
                                            />   
                                        </Col>                              
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={8} style={{padding:'5%'}}>
                                            <Title level={4}>香港曲棍球男子冬季聯賽第五週賽事</Title>
                                            <Title level={4}>男B隊  對手: 144U A</Title>
                                            <Title level={4}>男B隊同樣受到傷病影響，雖然全場只得12人應戰，但頭三節都互有攻勢及不少入肉攻勢，可惜體力問題，結果在第四節被對手連入兩球，以0:2不敵對手。</Title>
                                            <Title level={4}>賽果: 0:2</Title> 
                                        </Col>
                                        <Col span={16} >
                                            <img style={{width:'100%'}}
                                                alt="Sirius Hockey"
                                                src="/image/clubIntroduction.png"
                                            />   
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel> */}
                            <Row style={{paddingBottom:20}}> 
                                <Title level={3}>每週賽果</Title>
                            </Row>
                            <Row style={{paddingBottom:20}}>
                                <Col sm={24} lg={12} style={{paddingRight:20}}>
                                    <InstagramEmbed url="https://www.instagram.com/p/CmWDLe5BxhH/?utm_source=ig_web_copy_link"  />
                                </Col>
                                <Col sm={24} lg={12} style={{paddingLeft:20}}>
                                    <InstagramEmbed url="https://www.instagram.com/p/Clc0hUEBCFX/?utm_source=ig_web_copy_link"  />
                                </Col>
                            </Row>
                            <div style={{paddingBottom:20}}>
                                <Row >
                                    <Col span={16} >
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/carousel_1.jpg"
                                        />   
                                    </Col>
                                    <Col span={8} style={{padding:'5%'}}>
                                        <Title level={4}>Sirius Mini Hockey</Title>
                                        <Title level={4}>歡迎小朋友一齊參加</Title>
                                    </Col>
                                </Row>
                            </div>
                            <Carousel autoplay effect='scrollx' dotPosition="top" style={{backgroundColor: '#dbebfa',paddingBottom:20}}>
                                <div >
                                    <Row> 
                                        <Col span={8} style={{padding:'5%',backgroundColor: '#dbebfa'}}>
                                            <Title level={4}>由香港男子曲棍球代表隊成員余俊軒設計一系列課程</Title>
                                        </Col>
                                        <Col span={16} >
                                            <img style={{width:'100%'}}
                                                alt="Sirius Hockey"
                                                src="/image/carousel_4.jpg"
                                            />   
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={8} style={{padding:'5%',backgroundColor: '#dbebfa'}}>
                                            <Title level={4}>曾代表香港參與2018年印尼雅加達亞運會</Title>
                                        </Col>
                                        <Col span={16} >
                                            <img style={{width:'100%'}}
                                                alt="Sirius Hockey"
                                                src="/image/carousel_5.png"
                                            />   
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={8} style={{padding:'5%',backgroundColor: '#dbebfa'}}>
                                            <Title level={4}>2012年首次代表香港隊出賽，至今已獲得超過50頂國際賽喼帽</Title>
                                        </Col>
                                        <Col span={16} >
                                            <img style={{width:'100%'}}
                                                alt="Sirius Hockey"
                                                src="/image/carousel_6.png"
                                            />   
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel>
                            <div style={{backgroundColor: '#dbebfa',paddingBottom:20}}>
                                <Row>
                                    <Col span={16} >
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/carousel_7.png"
                                        />   
                                    </Col>
                                    <Col span={8} style={{padding:'5%',backgroundColor: '#dbebfa'}}>
                                        <Title level={4}>多次接受傳媒訪問，向公眾推廣曲棍球運動</Title>
                                    </Col>
                                </Row>
                            </div>                                
                        </>
                    }
                >
                </DefineCard>    
            </div>
            }
        </div>
    )
}
export default Home;