import {Typography } from "antd"
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const CourseIntroduction = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <DefineCard 
                title={<Title level={size.width < 800 ? 3 : 2}>課程簡介</Title>} 
                description={
                    <>
                        <Title level={size.width < 800 ? 4 : 3}>課程詳情即將公佈，請密切留意。</Title>
                        {/* <Title level={size.width < 800 ? 4 : 3}>訓練宗旨 - 先管後教</Title>
                        <ul>
                            <li><Title level={size.width < 800 ? 5 : 4}>促進兒童成長，減少兒童沉迷電子產品的習慣。</Title></li>
                            <li><Title level={size.width < 800 ? 5 : 4}>透過曲棍球運動教導兒童紀律、團體合作精神，以及與人相處之道。 </Title></li>
                       </ul>
                        <Title level={size.width < 800 ? 3 : 2}>課程內容: </Title>
                        <ul>
                            <li><Title level={size.width < 800 ? 5 : 4}>主要以遊戲形式令小朋友得到曲棍球體驗，從而培養對曲棍球興趣。 </Title></li>
                        </ul> */}
                    </>
                
                } 
                image={        
                    <img
                        alt="Sirius Hockey"
                        src="/image/courseIntroduction.jpg"
                    />   
                }>
                
            </DefineCard>
        </div>
        
    )
}

export default CourseIntroduction;