import {Typography,Row,Col } from "antd"
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const ClubIntroduction = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <DefineCard 
                title={<Title level={size.width < 800 ? 3 : 2}>球會介紹</Title>} 
                description={
                    <>
                        <Title level={size.width < 800 ? 4 : 3}>球會歷史: </Title>
                        <Title level={size.width < 800 ? 5 : 4}>天狼星曲棍球會(SIRIUS HOCKEY CLUB, 下稱SIRIUS) 創會於2019年成立，
                            旨在香港推廣及宣傳曲棍球運動，
                            以及為香港小童和青少年提供具系統化訓練，
                            目標是為未來香港代表隊成員作最好的準備。 
                        </Title>
                        <Title level={size.width < 800 ? 4 : 3}>球會宗旨: </Title>
                        <ul>
                            <li> <Title level={size.width < 800 ? 5 : 4}>SIRIUS青訓非常注重對個人技術和比賽的分析和回顧  </Title></li>
                            <li> <Title level={size.width < 800 ? 5 : 4}>建立了完善的青少年小型曲棍球聯賽體系</Title></li>
                            <li> <Title level={size.width < 800 ? 5 : 4}>注重階段性培養，遵循身體發展規律</Title></li>
                            <li> <Title level={size.width < 800 ? 5 : 4}>反對被動接受，注重對小球員個人主動性的培養</Title></li>
                            <li> <Title level={size.width < 800 ? 5 : 4}>學打球先學做人，培養良好的運動員品格</Title></li>
                        </ul>
                        <Title level={size.width < 800 ? 4 : 3}>球會球隊: </Title>
                        <Title level={size.width < 800 ? 5 : 4}>SIRIUS自2019年開始，積極參與由香港曲棍球總會(HKHA)主辦的聯賽及杯賽，
                            發展至今，
                            SIRIUS 一共派出2男隊1女隊參與比賽。
                        </Title>
                        <Row>
                            <Col md={8} lg={8} xg={24}>
                                <img style={{width:'100%',padding:10}}
                                    alt="Sirius Hockey"
                                    src="/image/clubTeam1.jpg"
                                />   
                            </Col>
                            <Col md={8} lg={8} xg={24}>
                                <img style={{width:'100%',padding:10}}
                                    alt="Sirius Hockey"
                                    src="/image/clubTeam2.jpg"
                                />   
                            </Col>
                            <Col md={8} lg={8} xg={24}>
                                <img style={{width:'100%',padding:10}}
                                    alt="Sirius Hockey"
                                    src="/image/clubTeam3.jpg"
                                />   
                            </Col>
                        </Row>
                    </>
                
                }  
                image={        
                    <img
                        alt="Sirius Hockey"
                        src="/image/clubIntroduction.png"
                    />   
                }>
            </DefineCard>
        </div>
        
    )
}

export default ClubIntroduction;