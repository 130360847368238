import {Link} from 'react-router-dom';
import { useContext,useState } from 'react';
import {Button,Menu,Drawer,PageHeader,Typography } from "antd"
import {Context} from './Utils/MyContext';
import useEffectOnce from './customHooks/useEffectOnce';
import useWindowSize from './customHooks/useWindowSize';
import menuArray from './Utils/menuObject';

import {
    BarsOutlined
  } from '@ant-design/icons';
const AppHeader = () => {

    const context = useContext(Context);
    const [visible, setVisible] = useState(false);
    const size = useWindowSize();
    const { Title } = Typography;

    const menuClick = (e)=> {
        context.setCurrentPage(e.key)
        setVisible(false)
    }     

    useEffectOnce(()=>{
        menuArray.forEach((menuItem) => {
            if((window.location.href).toLowerCase().includes(menuItem.key)){
                context.setCurrentPage(menuItem.key)
            }
        })
    })

    const showDrawer = () => {
        setVisible(true);
      };
    
      const onClose = () => {
        setVisible(false);
      };

    const menuItemStyle = {
        color:'black'
    }
    return (
        <div>
            {size.width < 800 ?
                <div style={{backgroundColor:'#082543'}}>
                    <PageHeader
                        className="site-page-header"
                        title={
                            <img 
                                alt="Sirius Hockey"
                                src="/image/siriusLogo.PNG"
                                style={{width:100}}
                            /> 
                        }
                        subTitle={
                            <>
                                <Title level={4} className="font-link" style={{color:'white'}}>天狼星曲棍球會</Title>  
                                <Title level={4} className="font-link" style={{color:'white'}}>SIRIUS HOCKEY CLUB</Title>                       
                            </>
                        }
                    />
                                          <PageHeader
                        className="site-page-header"
                        title={
                            <div style={{paddingLeft:30}}>
                                <Title level={5} className="font-link" style={{color:'white'}}>If you have a dream,</Title>     
                                <Title level={5} className="font-link" style={{color:'white'}}>give it a chance to happen.</Title> 
                            </div>                       
                        }

                    />                              
                    <Button
                        onClick={showDrawer}
                        type="primary"
                        style={{
                            background:'transparent',
                            borderColor:'transparent',
                            color:'#ED1B24',
                            boxShadow: '0 0px 0 rgb(0 0 0 / 5%)'
                        }}
                    >
                        {visible ? <BarsOutlined style={{fontSize:40}}/> : <BarsOutlined style={{fontSize:40}}/>}
                    </Button>
                </div>


                :
                <div style={{backgroundColor:'#082543'}}>
                    <PageHeader
                        className="site-page-header"
                        title={
                            <img 
                                alt="Sirius Hockey"
                                src="/image/siriusLogo.PNG"
                                style={{width:200}}
                            /> 
                        }
                        subTitle={
                            <>
                                <Title className="font-link" style={{color:'white', fontSize:26}}>天狼星曲棍球會 Sirius Hockey Club</Title>
                                <Title level={5} className="font-link" style={{color:'white', fontSize:26 }}>If you have a dream, give it a chance to happen.</Title> 
                            </>   
                        }
                    />
                    <Menu onClick={menuClick} selectedKeys={[context.currentPage]} mode="horizontal" theme="light" style={{paddingLeft:20,backgroundColor:'#ED1B24'}}>
                        {menuArray.map((menuItem) => 
                            (
                            <Menu.Item key={menuItem.key} >
                                <Link to={menuItem.link} style={ menuItemStyle }>
                                    <b>{menuItem.pageName}</b>
                                </Link>
                            </Menu.Item>
                            )
                        )}
                    </Menu>
                </div>
                
            }


            {visible ? 
                <Drawer placement="left" onClose={onClose} visible={visible}>
                    <Menu onClick={menuClick} selectedKeys={[context.currentPage]} mode="inline" theme="light">
                        {menuArray.map((menuItem) => 
                            (
                            <Menu.Item key={menuItem.key} >
                                <Link to={menuItem.link} style={ menuItemStyle }>
                                    <b>{menuItem.pageName}</b>
                                </Link>
                            </Menu.Item>
                            )
                        )}
                    </Menu>
                </Drawer>
                :
                <div></div>
            }
            
        </div>
    )
}
export default AppHeader