import { createContext,useState} from 'react';

export const Context = createContext();


const ContextProvider = (props) => {

    const [currentPage,setCurrentPage] = useState('home')

    return (

        <Context.Provider value={
            {currentPage,setCurrentPage}}>
            {props.children}
        </Context.Provider>
    )
}
export default ContextProvider;