import { Typography,Input,Button } from 'antd';
import useWindowSize from './customHooks/useWindowSize';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import DefineCard from './DefineCard'

const Membership = () => {

    const navigate = useNavigate();
    const { Title } = Typography;
    const size = useWindowSize();
    const [code,setCode] = useState("");
    const [errorMessage,setErrorMessage] = useState("密碼錯誤，請重新輸入");
    const [showErrorMessage,setShowErrorMessage] = useState(false);

    const login = () => {
        if (code === 'abcd'){
           sessionStorage.setItem('login',true) 
           navigate('/membership');
        }
        else{
            setShowErrorMessage(true);
        }
        
    }

    return(

        <>
            {sessionStorage.getItem('login') ? 
                <div style={{margin:'5%',textAlign: 'justify'}}>
                    <DefineCard 
                        title={<Title level={size.width < 800 ? 3 : 2}>會員專區</Title>} 
                        description={
                            <>
                                <Title level={size.width < 800 ? 4 : 3}>會員專享內容</Title>
                            </>
                        
                        } 
                        image={        
                            <img
                                alt="Sirius Hockey"
                                src="/image/courseIntroduction.jpg"
                            />   
                        }>
                        
                    </DefineCard>
                </div> : 
                
                <div style={{margin:'5%',textAlign: 'justify'}}>
                    <Title level={size.width < 800 ? 3 : 2}>會員專區</Title>
                    <Title level={size.width < 800 ? 4 : 3}>請輸入會員專用碼</Title>
                    <Input  style={{marginTop:50}} value={code} onChange={(e) => setCode(e.target.value)}/>
                    {showErrorMessage ? <Title style={{color:'red'}} level={size.width < 800 ? 5 : 4}>{errorMessage}</Title> : <></> }   
                    <Button type="primary" style={{marginTop:50,backgroundColor:'#082543',borderColor:'#082543'}} onClick={login}>提交</Button>
                                         
                </div>}
        </>
    )
}

export default Membership;