import {Typography,Col,Row,Carousel } from "antd"
import { InstagramEmbed } from 'react-social-media-embed';
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const LatestInformation = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <DefineCard 
                title={<Title level={size.width < 800 ? 3 : 2}>最新資訊</Title>} 
                description={
                    <>
                        <Row style={{paddingBottom:20}}> 
                            <Col span={size.width < 700 ? 24 : 16}>
                                <InstagramEmbed url="https://www.instagram.com/p/Cl-zRnGJPvU/?utm_source=ig_web_copy_link"  /> 
                            </Col>
                            <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                <Title level={size.width < 700 ? 5 : 4}>
                                    Best of luck to the HK Junior Team who will play the Test Series in Singapore tonight to prepare Junior AHF Cup in Jan 2023.
                                </Title>
                                <Title level={size.width < 700 ? 5 : 4}>
                                    Our Head coach Tony Yu will be one of the Senior Player and also be assistant coach to lead the Junior Team against SGP team.
                                </Title>
                            </Col>  
                        </Row>     
                        <Row style={{paddingBottom:20}}> 
                            <Col span={size.width < 700 ? 24 : 16}>
                                <img style={{width:'100%'}}
                                    alt="Sirius Hockey"
                                    src="/image/sirius_training.jpg"
                                />   
                            </Col>
                            <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                <Title level={size.width < 700 ? 5 : 4}>💡Pitch Confirmed ❗</Title>
                                <Title level={size.width < 700 ? 5 : 4}>Location: Happy Valley Hockey Pitch 11</Title>
                                <Title level={size.width < 700 ? 5 : 4}>Date: 21,28/10; 4,11,18,25/11; 2,16/12 (Every Friday night)</Title>
                                <Title level={size.width < 700 ? 5 : 4}>Time: 20:30-22:00</Title>
                                <Title level={size.width < 700 ? 5 : 4}>Enquiry: siriushockeyclub2019@gmail.com</Title>
                            </Col>  
                        </Row>
                        <Row style={{paddingBottom:20}}> 
                            <Col span={size.width < 700 ? 24 : 16}>
                                <img style={{width:'100%'}}
                                    alt="Sirius Hockey"
                                    src="/image/latestInformation_3.jpg"
                                />   
                            </Col>
                            <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                <Title level={size.width < 700 ? 5 : 4}>經過兩年在Div 4的洗禮，SIRIUS女隊中場主將Rachel Ng於2022-23球季轉投超聯爭標球隊Coyotez，正式挑戰高水平比賽。 祝願Rachel順利融入球隊，爭取更好表現！</Title>
                            </Col>  
                        </Row>
                        <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_5_1.jpg"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第五週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>男A隊 對手: RHOBA A</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>男A隊上半場以短角球先入兩球領先上半場，下半場男A隊體力透支，加上受傷病影響，只得11人應戰，被對手全場緊迫下連入三球，結果以2:3反敗對手。</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>賽果: 輸2:3</Title> 
                                        <Title level={size.width < 700 ? 5 : 4}>入球: Michael Chow x2</Title>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/clubIntroduction.png"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第五週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>男B隊  對手: 144U A</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>男B隊同樣受到傷病影響，雖然全場只得12人應戰，但頭三節都互有攻勢及不少入肉攻勢，可惜體力問題，結果在第四節被對手連入兩球，以0:2不敵對手。</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>賽果: 0:2</Title> 
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>
                        <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                            <div>
                                <Row> 
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_4_1.jpg"
                                        />   
                                    </Col> 
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球女子冬季聯賽第四週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>女隊迎戰經驗豐富的HKU隊，多次叩門都無功而還，結果0:0和局收場。</Title>
                                    </Col>                               
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_4_2.jpg"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第四週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>男B隊  對手: Elite D</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>雙方上半場互有攻守，男B在第二節把握一次反攻機會先入一球。但雙方都受制某因素以影響各自發揮，導致僵持局面出現。</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>下半場男B受制對手的強力傳球，造成多次險象。幸好，男B頂得住壓力守住比數，結果以一球小勝對手。 </Title> 
                                        <Title level={size.width < 700 ? 5 : 4}>入球: Thor Ng</Title>
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>
                        <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                            <div>
                                <Row> 
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_3_1.jpg"
                                        />   
                                    </Col> 
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球女子冬季聯賽第三週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>女隊以一球小勝Antlers B，取得今季首勝。 </Title>
                                        <Title level={size.width < 700 ? 5 : 4}>入球: Hilary </Title>
                                        <Title level={size.width < 700 ? 5 : 4}>*同場加影為2021-22年度Div 4 亞軍的團體照 </Title>
                                    </Col>                               
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16} >
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_3_2.jpg"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第三週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>男A隊  對手: HKFC E  </Title>
                                        <Title level={size.width < 700 ? 5 : 4}>雙方上半場互無紀錄，下半場男A先入一球，可惜後繼不計，被對手連入2球，結果以1:2不敵對手。 </Title>
                                        <Title level={size.width < 700 ? 5 : 4}>入球: Sam Au </Title>
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>

                        <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                            <div>
                                <Row> 
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_2_1.jpg"
                                        />   
                                    </Col> 
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球女子冬季聯賽第二週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>女隊今個星期六在多名新球員初嘗首戰之下，以0:1力敗於HKFC G。</Title>
                                    </Col>                               
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16} >
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_2_2.jpg"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第二週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>先輸2球，後入3球反勝贏3:2，男A取得今季首勝。</Title>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16} >
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_2_3.jpg"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第二週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>與對手DBS B隊互有攻守，爭持不下，最後男B在Q4取得點球，以1球小勝，取得今季首勝</Title>
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>

                        <Carousel autoplay effect='scrollx' dotPosition="top" style={{paddingBottom:20}}>
                            <div>
                                <Row> 
                                    <Col span={size.width < 700 ? 24 : 16}>
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_1_1.jpg"
                                        />   
                                    </Col> 
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球女子冬季聯賽第一週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>升班後Div3首場，女隊僅以一球差距敗於HKFC F隊。</Title>
                                    </Col>                               
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={size.width < 700 ? 24 : 16} >
                                        <img style={{width:'100%'}}
                                            alt="Sirius Hockey"
                                            src="/image/week_1_2.jpg"
                                        />   
                                    </Col>
                                    <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                        <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽第一週賽事</Title>
                                        <Title level={size.width < 700 ? 5 : 4}>2022-23年開季首戰，由於其中一方的球證缺席，不幸地需要由我隊2名合資格球證的球員充當比賽球證，使實力大減，結果以0:3敗於Antlers B。</Title>
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>




                        <Row style={{paddingBottom:20}}> 
                            <Col span={size.width < 700 ? 24 : 16}>
                                <img style={{width:'100%'}}
                                    alt="Sirius Hockey"
                                    src="/image/latestInformation_1.jpg"
                                />   
                            </Col>
                            <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                <Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽</Title>
                                <p><Title level={size.width < 700 ? 5 : 4}>香港曲棍球男子冬季聯賽已於2022年9月18日開鑼，今季Sirius Hockey 派出兩隊男子隊於Division 3 及 Division 5 角逐。</Title></p>
                                <p><Title level={size.width < 700 ? 5 : 4}>詳細男子賽程請查閱以下連結:</Title></p>
                                <p><Title level={size.width < 700 ? 5 : 4}><a href="http://www.hockey.org.hk/MenFixture.asp?ClubId=126#18092022">http://www.hockey.org.hk/MenFixture.asp?ClubId=126#18092022</a></Title></p>
                            </Col>  
                        </Row>
                        <Row style={{paddingBottom:20}}> 
                            <Col span={size.width < 700 ? 24 : 16}>
                                <img style={{width:'100%'}}
                                    alt="Sirius Hockey"
                                    src="/image/latestInformation_2.jpg"
                                />   
                            </Col>
                            <Col span={size.width < 700 ? 24 : 8} style={{padding:'5%'}}>
                                <Title level={size.width < 700 ? 5 : 4}>香港曲棍球女子冬季聯賽</Title>
                                <p><Title level={size.width < 700 ? 5 : 4}>香港曲棍球女子冬季聯賽已於2022年9月17日開鑼，今季Sirius Hockey 派出一隊女子隊於Division 3 角逐。</Title></p>
                                <p><Title level={size.width < 700 ? 5 : 4}>詳細女子賽程請查閱以下連結:</Title></p>
                                <p><Title level={size.width < 700 ? 5 : 4}><a href="http://www.hockey.org.hk/WomenFixture.asp?ClubId=134">http://www.hockey.org.hk/WomenFixture.asp?ClubId=134</a></Title></p>
                            </Col>  
                        </Row>
                    </>
                    } 
                >
            </DefineCard>
        </div>
        
    )
}

export default LatestInformation;