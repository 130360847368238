import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Card, Typography } from 'antd';

const { Meta } = Card;
const { Title } = Typography;

const DefineCard = ({description,title,titleUnderCoverPhoto,image}) => (
  <Card
    title={<Title>{title}</Title>}
    cover={image}
    style={{backgroundColor:'#dbebfa',border:'#dbebfa'}}
  >
    <Meta
      title = {titleUnderCoverPhoto}
      description={<Title level={4}>{description}</Title>}
    />
  </Card>
);

export default DefineCard;