import {Typography } from "antd"
import {
    FacebookOutlined,
    InstagramOutlined,
    EditFilled,
    CopyrightOutlined
  } from '@ant-design/icons';
import useWindowSize from './customHooks/useWindowSize';
const AppFooter = () => {

    const size = useWindowSize();
    const { Title } = Typography;
    return (
        <>
            <div style={{backgroundColor:'#082543'}}>
                <Title level={size.width < 800 ? 5:3} style={{paddingLeft:30,paddingTop:50,color:'white'}}> Follow us on Facebook and Instagram</Title>
                <Title level={size.width < 800 ? 5:3} style={{paddingLeft:30,color:'white'}}><FacebookOutlined /> Facebook : <a href="https://www.facebook.com/siriusfieldhockey">Sirius HK Field Hockey</a> </Title>
                <Title level={size.width < 800 ? 5:3} style={{paddingLeft:30,color:'white'}}><InstagramOutlined /> Instagram : <a href="https://www.instagram.com/sirius_hockey/">sirius_hockey</a></Title>
                <Title level={size.width < 800 ? 5:3} style={{paddingLeft:30,color:'white'}}><EditFilled /> Email : siriushockeyclub2019@gmail.com </Title>
                <Title level={size.width < 800 ? 5:3} style={{paddingLeft:30,paddingBottom:50,color:'white'}}><CopyrightOutlined /> 2019-2022 Sirius Hockey All Rights Reserved</Title>
            </div>
        </>
    )
}
export default AppFooter