import 'antd/dist/antd.css';
import './index.css';
import { Input,Button,Typography } from 'antd';
import emailjs from "emailjs-com"
import {useState} from 'react';
import { Link } from "react-router-dom"
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const { Title } = Typography;
const { TextArea } = Input;
const ContactUs = () => {

    const [textBoxContent,setTextBoxContent] = useState("");
    const [mobilePhoneNumber,setMobilePhoneNumber] = useState("");
    const [emailAddress,setEmailAddress] = useState("");
    const [name,setName] = useState("");
    const size = useWindowSize();
    const templateParams = {
        name: name,
        mobilePhone: mobilePhoneNumber,
        emailAddress: emailAddress,
        textBoxContent: textBoxContent
      };
      
    const sendEmail = () => {
        emailjs.send('service_nsvaoip','template_xw2of4w', templateParams,'JMnV8Id36sGMnyk0U')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
        }, (err) => {
          console.log('FAILED...', err);
        });
      }

    return (
      <div style={{margin:'5%',textAlign: 'justify'}}>
          <DefineCard 
              title={<Title level={size.width < 800 ? 3 : 2}>聯絡我們</Title>} 
              description={            
                <>
                  <Title level={size.width < 800 ? 4 : 3}>如有任何查詢，歡迎填寫下方表格，我們會有專人接洽。</Title>
                  <Input  style={{marginTop:50}} placeholder="姓名" value={name} onChange={(e) => setName(e.target.value)}/>
                  <Input  style={{marginTop:50}} placeholder="電話號碼" value={mobilePhoneNumber} onChange={(e) => setMobilePhoneNumber(e.target.value)}/>
                  <Input  style={{marginTop:50}} placeholder="電郵地址" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)}/>
                  <TextArea   style={{marginTop:50}} rows={4} placeholder="查詢內容" value={textBoxContent} onChange={(e) => setTextBoxContent(e.target.value)} />
                  <Link to={"/email-confirmation"} className="col-padding">
                        <Button type="primary" style={{marginTop:50,backgroundColor:'#082543',borderColor:'#082543'}} onClick={sendEmail}>提交</Button>
                  </Link>
                </>
              }
            >
          </DefineCard>
      </div>

    )
}
export default ContactUs