import {Typography } from "antd"
import DefineCard from './DefineCard'
import useWindowSize from './customHooks/useWindowSize';
const TrainingSchedule = () => {
    const { Title } = Typography;
    const size = useWindowSize();
    return(
        <div style={{margin:'5%',textAlign: 'justify'}}>
            <DefineCard 
                title={<Title level={size.width < 800 ? 3 : 2}>訓練日期</Title>} 
                description={
                    <>
                        <Title level={size.width < 700 ? 5 : 4}>💡Pitch Confirmed ❗</Title>
                        <Title level={size.width < 700 ? 5 : 4}>Location: Happy Valley Hockey Pitch 11</Title>
                        <Title level={size.width < 700 ? 5 : 4}>Date: 21,28/10; 4,11,18,25/11; 2,16/12 (Every Friday night)</Title>
                        <Title level={size.width < 700 ? 5 : 4}>Time: 20:30-22:00</Title>
                        <Title level={size.width < 700 ? 5 : 4}>Enquiry: siriushockeyclub2019@gmail.com</Title>
                    </>

                } 
                image={        
                    <img
                        alt="Sirius Hockey"
                        src="/image/sirius_training.jpg"
                    />   
                }>
            </DefineCard>
        </div>
        
    )
}

export default TrainingSchedule;